import React, { useContext } from 'react'
import HomeServices from '../components/HomeServices'
import ImagesSlide from '../components/ImagesSlide'
import styles from './home.module.css'
import { store } from '../components/HOC/State'
import { translate } from './translate'


export default function Home({children}) {
    const {selectedLang} = useContext(store)
    let lang = translate[selectedLang]
  return (
    <div>
      <div className={styles['home-div']}>
        <div>
            {/* <img src='/fon.webp' alt='main' className={styles['fon-image']}/> */}
            <div className={styles['on-animation']}>
                <div className={styles['name-h']}>
                  {/* <div className={styles['logo-image-div']}>
                    <img className={styles['logo-image']} src="/logo.svg" alt="logo" />
                  </div>
                  <div>
                      <h1 >{lang.tr_nameOfOrganization}</h1>
                      <h1>{lang.tr_nameOfOrganization2}</h1>
                  </div> */}
                  <h3>
                      Since its foundation in 1956, Yerevan Computer Research Development Institute named after renowned scientist  Sergey Mergelyan,  with a rich history in designing and manufacturing a variety of computing technologies and systems, served as a catalyst for scientific and technological development within Armenia. 
                  </h3>
                  <h3>
                     By being a forward-thinking company and leveraging its rich legacy,  YCRDI  is embracing new technologies and driving innovations to contribute to the military and industrial sectors. 
                  </h3>
                </div>

                <div className={styles['image-back']}>
                    <img src='/building.jpg' alt="nkar"/>
                </div>
            </div>
        </div>
        {/* <HomeServices /> */}
        {/* {children} */}
      </div>
      <div className={styles['offers']}>
        <h2>What we offer</h2>
        <div className={styles['offers-list']}>
          <div className={styles['offers-each']}>
              <p>Cryptographic</p>
              <p>Devices</p>
          </div>
          <div className={styles['offers-each']}>
            <p>Shooting</p>
            <p>Simulators</p>
          </div>
          <div className={styles['offers-each']}>
            <p>Audio-Video</p>
            <p>Conference</p>
          </div>
        </div>
      </div>
      <ImagesSlide />
    </div>
    
  )
}
